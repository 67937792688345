<template>
  <b-container class="overflow-auto" fluid>
    <a @click="goBack" style="border: 1px black"><span class="fa fa-long-arrow-left"></span> Back</a>
    <!-- SM screen -->
    <h5 class="my-3 d-block d-sm-none" style="max-width: 450px; min-width: 140px; word-wrap: break-word;">Showing Remote Video Zones for advertiser: <strong class="" >{{ typeof(remotevideozone.list[0])==='undefined' ? '': remotevideozone.list[0].AdvertiserName }}</strong> </h5>
    <!-- * EXCEPT SM screen -->
    <h5 class="my-3 d-none d-sm-block" >Showing Remote Video Zones for advertiser: <strong class="">{{ typeof(remotevideozone.list[0])==='undefined' ? '': remotevideozone.list[0].AdvertiserName }}</strong> </h5>

    <!-- Ad Interface controls -->
    <a-row>
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'ortb-remote-video-zones'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'display-remotevideozones-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :bordered="true"
      :current-page="currentPage"
      :fields="filteredFields"
      :filter="filter"
      :hover="true"
      :items="showRemoteVideoZones"
      :per-page="perPage"
      :small="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :sort-compare="sortCompare"
      :striped="true"
      :tbody-tr-class="rowClass"
      show-empty
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(Searchfeeds)="data">
        <div class="items-center">
          {{ data.item.Searchfeeds }}
        </div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">
          {{ data.item.Name }}
        </div>
      </template>
      <template #cell(AdvertiserName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.AdvertiserName.length<=20">{{ data.item.AdvertiserName }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;" v-if="data.item.AdvertiserName.length>20">{{ data.item.AdvertiserName }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.AdvertiserName" class="" v-if="data.item.AdvertiserName.length<20">{{ data.item.AdvertiserName }}</div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.AdvertiserName" class="" v-if="data.item.AdvertiserName.length>=20">{{ data.item.AdvertiserName.substring(0,20)+".." }}</div>
          </div>
        </div>
      </template>
      <template #cell(Videozones)="data">
        <div v-if="data.item.Videozones.length > 0">
          <a-tooltip slot="addonBefore" trigger="click" :overlayStyle="{ 'max-width': '200%' }">
            <template slot="title">
              <div className="zone-list">
                <div :key="zone.Id" v-for="zone in videozones">
                  {{
                    data.item.Videozones.includes(zone.Id) ? `[${zone.Id}] ${zone.Name}` : ''
                  }}
                </div>
              </div>
            </template>
            <a-button type="primary">
              {{ data.item.Videozones.length }}
            </a-button>
          </a-tooltip>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleRemoteVideoZoneStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(SeatListMode)="data">
        {{ data.item.SeatListMode === 0 ? 'Blacklist' : 'Whitelist' }}
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop :to="{ name: 'remotevideozone-update', params: { id: data.item.Id },  }">
          <b-button
            class="btn-info mr-1"
            size="sm"
            @click="editAd(data.item.Id)"
          ><i class="fa fa-edit"></i></b-button>
        </router-link>
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="copyRemoteVideoZone(data.item.Id , data.item)"
        ><i class="fa fa-copy"></i></b-button>
      </template>
    </b-table>
<!-- AdvertiserName -->
    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button v-if="advertiserStatus===0" class="btn btn-success px-5" @click="redirectToCreateAd()">Create Remote Video Zone</button>
        <b-alert show variant="danger" v-else> Advertiser is Deactivated </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneRemoteVideoZone } from '@/api/display/remotevideozone'
import { getAdvertisers } from '@/api/display/advertiser'
import { getVideozonesShort } from '@/api/display/videozone'

export default {
  props: ['advertiser'],
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      advertiserStatus: 0,
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'Zone ID',
          show: true,
          headerTitle: 'Zone ID',
          sortable: true,
          thStyle: 'width:80px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Zone Name',
          show: true,
          headerTitle: 'Zone Name',
          sortable: true,
        },
        {
          key: 'AdvertiserName',
          label: 'Advertiser',
          show: true,
          headerTitle: 'Advertiser Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'Videozones',
          label: 'Video Zones',
          show: true,
          headerTitle: 'Video Zones',
          sortable: true,
          tdClass: 'text-center',
          thStyle: 'width: 105px',
        },
        {
          key: 'BidsDailyLimit',
          label: 'Bids Daily Limit',
          show: true,
          headerTitle: 'Bids Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:130px',
          summed: false,
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'ZoneUrl',
          label: 'Zone URL',
          show: false,
          headerTitle: 'Zone URL',
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: true,
          headerTitle: 'Description',
          sortable: true,
        },
        {
          key: 'AccManagerId',
          label: 'Acc. Mngr. ID',
          show: false,
          headerTitle: 'Account Manager ID',
          sortable: true,
          thStyle: 'width:110px',
          tdClass: 'text-right',
        },
        {
          key: 'AccManagerName',
          label: 'Account Manager',
          show: true,
          headerTitle: 'Account Manager Name',
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      videozones: null,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['remotevideozone', 'settings']),
    ...mapGetters('remotevideozone', ['getRemoteVideoZones', 'getActiveRemoteVideoZones']),
    showActive: {
      get: function () {
        return this.settings.showActiveAdvertiserRemoteVideoZones
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveAdvertiserRemoteVideoZones', value })
      },
    },
    showRemoteVideoZones() {
      return this.showActive ? this.getActiveRemoteVideoZones : this.getRemoteVideoZones
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showRemoteVideoZones.length
    },
  },
  methods: {
    sortCompare(a, b, key) {
      if (key === 'Rtbzones' || key === 'Videozones') {
        const lenA = Array.isArray(a[key]) ? a[key].length : 0
        const lenB = Array.isArray(b[key]) ? b[key].length : 0
        return lenA - lenB
      }
      return a[key] > b[key] ? 1 : (a[key] < b[key] ? -1 : 0)
    },
    redirectToCreateAd() {
      // this.$router.push('/display/remotevideozone/create/' + this.$route.params.id)
      this.$router.push({ name: 'create-remotevideozone-advertiserId', params: { advertiserId: this.$route.params.id } }).catch(() => {})
    },
    goBack() {
      this.$router.go(-1)
    },
    getAd(id) {
      cloneRemoteVideoZone(id).then(response => {
        if (response) {
          // this.$router.push('/display/remotevideozone/update/' + response.Remotevideozone.Id)
          this.$router.push({ name: 'remotevideozone-update', params: { id: response.Remotevideozone.Id } }).catch(() => {})
          this.$notification.success({
            message: 'Remote Video Zone cloned',
            description: `Remote Video Zone: ${response.Remotevideozone.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyRemoteVideoZone(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone Remote Video Zone?',
        content: h => <div>{status} Clone Remote Video Zone: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.getAd(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editAd(id) {
      // this.$router.push('/display/remotevideozone/update/' + id)
      this.$router.push({ name: 'remotevideozone-update', params: { id: id } }).catch(() => {})
    },
    toggleRemoteVideoZoneStatus(ad) {
      const status = ad.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change Remote Video Zone status?',
        content: h => <div>{status} Remote Video Zone <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = ad.Status === 0 ? 1 : 0
          this.$store.dispatch('remotevideozone/CHANGE_STATUS', {
            id: ad.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('remotevideozone/LOAD_ZONES_FOR_ADVERTISER', { AdvertiserId: this.$route.params.id })
    getAdvertisers(this.$route.params.id).then(response => {
      if (response) {
        this.advertiserStatus = response.Status
      }
    })
    getVideozonesShort()
      .then(res => {
        this.videozones = res
      })
  },
  mounted() {
  },
}
</script>

<style>

</style>
